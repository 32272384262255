<script>




export default {


    props: ['data'],
    components: {

    },


    data() {

        return {
            disabled: true,
            agent : {
                first_name : '',
                last_name : '',
                basic : {
                    
                },
                key_date : {

                },
                fees: {},
                fields: {
                tax_info : {
                    first_name : '',
                    last_name : '',
                    is_prec : 0,
                },
                },
                bill: {items : []}
            }
        }

    },


    methods: {

    },

    created() {

    },

    mounted() {

    },

    watch: {
        data(n) {
            console.log(n)
            this.agent = n
        }
    },

};
</script>


<template>
    <div class="row">
        <div class="card-body">
            <div class="row">

                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    01
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Basic Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">First Name</label>
                                        <input type="text" class="form-control" :disabled="disabled" v-model="agent.first_name" />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                        <input type="text" class="form-control" :disabled="disabled"
                                            v-model="agent.last_name" />
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">VIN</label>
                                        <input type="text" class="form-control" v-model="agent.code"
                                            :disabled="disabled" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">CODE</label>
                                        <input type="text" class="form-control" v-model="agent.LWA_userName"
                                            :disabled="disabled" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-input">PM Code</label>
                                        <input type="text" class="form-control" v-model="agent.property_mgt_code"
                                            :disabled="disabled" />
                                    </div>
                                </div>



                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label for="choices-office-input" class="form-label">Office</label>
                                        <input type="text" class="form-control" v-model="agent.office_code"
                                            :disabled="disabled" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label for="choices-office-input" class="form-label">REMAX ID</label>
                                        <input type="text" class="form-control" 
                                            :disabled="disabled" />
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label for="choices-office-input" class="form-label">BCFSA</label>
                                        <input type="text" class="form-control" 
                                            :disabled="disabled" />
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
                <!-- end card -->

                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    02
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Personel Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-email-input">Email :</label>
                                    <input type="email" class="form-control" v-model="agent.email"
                                        id="contact-info-email-input" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">Phone :</label>
                                    <input type="text" class="form-control" v-model="agent.phone" :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                    <div class="mb-md-0">
                                        <label for="contact-info-birthday-input" class="form-label">Birthday</label>
                                        <input type="email" class="form-control" v-model="agent.birthday"
                                            :disabled="disabled" />
                                    </div>
                                </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="mb-md-0">
                                    <label for="contact-info-location-input" class="form-label">Location</label>
                                    <input type="text" class="form-control" v-model="agent.address"
                                        :disabled="disabled" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-description-input">Description</label>
                                    <textarea class="form-control" :disabled="disabled" rows="3"></textarea>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!-- end card -->


                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    03
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">Tax Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">First Name</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.first_name"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                    <input type="text" class="form-control" :disabled="disabled"
                                        v-model="agent.fields.tax_info.last_name" />
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">GST Number</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.gst_number"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">SIN Number</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.sin_number"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">Unit</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.unit"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Address</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.address"
                                        :disabled="disabled" />
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-name-input">City</label>
                                    <input type="text" class="form-control" v-model="data.fields.tax_info.city"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Province</label>
                                    <input type="text" class="form-control" id="gen-info-designation-input"
                                        :disabled="disabled" v-model="agent.fields.tax_info.province" />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label class="form-label" for="gen-info-designation-input">Postal Code</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.postal_code"
                                        :disabled="disabled" />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <!-- end card -->

                <!-- PREC Info -->
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    04
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">PREC Info</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="mt-4">
                                    <label class="form-label" for="gen-info-designation-input">Is PREC</label>
                                    <input type="checkbox" class="form-check-control" :disabled="disabled" />
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-licence-number-input">PREC Name :</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.prec_name"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-phone-input">ITN :</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.prec_itn"
                                        :disabled="disabled" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <label for="contact-info-birthday-input" class="form-label">PREC Date</label>
                                    <input type="text" class="form-control" v-model="agent.fields.tax_info.prec_date"
                                        :disabled="disabled" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- end card -->

            </div>
        </div>
        <!-- end card body -->


    </div>
    <!-- end row -->
</template>